import React from "react"
import PropTypes from "prop-types"

import ColoredBlock from "../ColoredBlock"
import { COLORS } from "../../styles/constants"

import SectionHeader from "../section-header"

const Privacy = ({ siteTitle }) => (
  <ColoredBlock
    backgroundColor={COLORS.accent}
    color={COLORS.primaryBlack}
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    }}
  >
    <SectionHeader title="Privacy" />
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      This game, Stargazer, does not collect or store any information about its
      users. There is absolutely no server or service associated with the game
      outside of your device.
    </p>
  </ColoredBlock>
)

Privacy.propTypes = {
  siteTitle: PropTypes.string,
}

Privacy.defaultProps = {
  siteTitle: ``,
}

export default Privacy
