import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Privacy from "../components/stargazer/privacy"
import Contact from "../components/stargazer/contact"

import SectionHeader from "../components/section-header"
import ColoredBlock from "../components/ColoredBlock"
import { COLORS } from "../styles/constants"

import stargazerPreview from "../images/stargazer-big.png"

const IndexPage = () => (
  <Layout>
    <SEO />
    <ColoredBlock
      backgroundColor={"#fff"}
      color={COLORS.primaryBlack}
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h2 style={{ color: COLORS.accent }}>Stargazer</h2>

      <div style={{ position: `relative`, padding: 0 }}>
        <img
          style={{ margin: 0 }}
          src={stargazerPreview}
          alt="a blank card floating over colorful graphics"
        />
        <div
          style={{
            zIndex: 99,
            position: "absolute",
            bottom: "0",
            left: 0,
            width: `100%`,
            background: `url(/bottom-fade.png)`,
            height: "200px",
          }}
        />
      </div>

      <p style={{ textAlign: "center", maxWidth: 440 }}>
        Fly through space collecting stars and dodging asteroids.
      </p>
    </ColoredBlock>
    <Privacy />
    <Contact />
  </Layout>
)

export default IndexPage
